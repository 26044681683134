import React, { useContext, useState, useEffect } from "react";
import { useAuth } from "./auth-context";
import { getStepIntro } from "../utils/auth-utils";
import { useClient } from "../utils/use-client";

export const WalkthroughContext = React.createContext({});
WalkthroughContext.displayName = "WalkthroughContext";

export default function WalkthroughProvider(props) {
  const { user } = useAuth();
  const [step, setStep] = useState(parseInt(getStepIntro()));
  const [isIntroRunning, setIsIntroRunning] = React.useState(false);
  const [releaseNotes, setReleaseNotes] = useState({});
  const client = useClient();

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      setIsIntroRunning(!user.extra.seenWalkthrough);

      client("/util/v3/getWhatsNew", {
        method: "POST",
        body: { t_user_id: user.uid }
      })
        .then(({ data }) => setReleaseNotes(data))
        .catch(e => console.log(e));
    }
  }, [user]);

  const updateStep = React.useCallback(
    number => {
      setStep(number);
    },
    [setStep]
  );

  const updateIsRunning = React.useCallback(
    value => {
      setIsIntroRunning(value);
    },
    [setIsIntroRunning]
  );

  return (
    <WalkthroughContext.Provider
      value={{
        step: step,
        isIntroRunning: isIntroRunning,
        updateStep: number => updateStep(number),
        updateIsRunning: value => updateIsRunning(value),
        releaseNotes: releaseNotes
      }}
      {...props}
    ></WalkthroughContext.Provider>
  );
}

export function useWalkthrough() {
  const context = useContext(WalkthroughContext);

  if (context === undefined) {
    throw new Error("useWalkthrough must be used within a WalkthroughProvider");
  }

  return context;
}
