import { Role } from "../role-utils";
import TeamManager from "./TeamManager";
import Worker from "./Worker";

export function FacilityManager() {
  this.id = "da80a";
  this.name = "Facility Manager";
  this.type = 2;
  // this.uid = '';
  this.below = [new TeamManager(), new Worker()];
}

FacilityManager.prototype = Role.prototype;

FacilityManager.id = "da80a";
// FacilityManager.name = "Facility Manager";

export default FacilityManager;