import { Role } from '../role-utils';
import Admin from './Admin';
import FacilityManager from './FacilityManager';
import TeamManager from './TeamManager';
import Worker from './Worker';

function SuperAdmin() {
  this.id = 'd4c39';
  this.name = 'Super Admin';
  this.type = 0;
  this.below = [
    new Admin(),
    new FacilityManager(),
    new TeamManager(),
    new Worker(),
  ];
}

SuperAdmin.id = 'd4c39';

SuperAdmin.prototype = Role.prototype;

export default SuperAdmin;
