import { Role } from '../role-utils';

function Worker() {
  this.id = "168e0";
  this.name = "Worker";
  this.type = 4;
  // this.uid = '';
}

Worker.prototype = Role.prototype;

Worker.id = "168e0";
// Worker.name = "Worker";

export default Worker;