import { fetchToken, getFromSession } from './auth-utils';

const MAIN_URL = `${process.env.REACT_APP_MAIN_API}`;
const METRICS_URL = `${process.env.REACT_APP_METRICS_API}`;
const MESSAGES_URL = `${process.env.REACT_APP_MESSAGES_API}`;

function getBaseUrl(api) {
  switch (api) {
    case 'main':
      return MAIN_URL;
    case 'metrics':
      return METRICS_URL;
    case 'messages':
      return MESSAGES_URL;
    default:
      return MAIN_URL;
  }
}

function client(endpoint, { body = {}, api = 'main', ...customConfig }) {
  // Clean endpoint from leading and trailing slashes /
  const cleanEndpoint = endpoint.replace(/^\/|\/$/g, '');
  const token = fetchToken();
  const sessionId = getFromSession('tempId');
  const baseUrl = getBaseUrl(api);
  const headers = { 'Content-Type': 'application/json' };

  if (token) {
    headers['rufus-access-token'] = token;
  }

  if (sessionId) {
    // body.session_id = sessionId;
    headers['session-id'] = sessionId;
  }

  const config = {
    ...customConfig,
    body: JSON.stringify(body),
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  return window
    .fetch(`${baseUrl}/${cleanEndpoint}`, config)
    .then(async response => {
      let body = await response
        .json()
        .catch(() => Error('Something went wrong, please try again.'));

      if (response.ok) {
        // Check if token has expired.
        if (body.status === 500) {
          throw new ExpiredTokenError();
        }

        if (body.status === 404) {
          throw new NotFoundError(body.message);
        }

        if (body.status && body.status !== 200 && body.status !== 202) {
          throw new Error(
            body.message || 'Something went wrong, please try again.'
          );
        }

        return body;
      }

      if (response.status === 500) {
        throw new ExpiredTokenError();
      }

      throw new Error(
        body.message || 'Something went wrong, please try again.'
      );
    });
}

export function ExpiredTokenError() {
  this.name = 'ExpiredTokenError';
  this.message = 'Your session has expired. Please Log In again.';
  this.stack = new Error().stack;
}
ExpiredTokenError.prototype = Object.create(Error.prototype);
ExpiredTokenError.prototype.constructor = ExpiredTokenError;

export function NotFoundError(message) {
  this.name = 'NotFoundError';
  this.message = message || 'Resource not found';
  this.stack = new Error().stack;
}
NotFoundError.prototype = Object.create(Error.prototype);
NotFoundError.prototype.constructor = NotFoundError;

export function mediaClient(endpoint, { body = {}, api = 'main' }) {
  const cleanEndpoint = endpoint.replace(/^\/|\/$/g, '');
  const token = fetchToken();
  const sessionId = getFromSession('tempId');
  const baseUrl = getBaseUrl(api);

  try {
    return window
      .fetch(`${baseUrl}/${cleanEndpoint}`, {
        method: 'POST',
        //Sending in headers the session data instead of the body
        //because the body can't be transformed to JSON for uploading media.
        headers: {
          'rufus-access-token': token,
          'session-id': sessionId,
        },
        body: body,
      })
      .then(async response => {
        let body = await response
          .json()
          .catch(() => Error('Something went wrong, please try again.'));

        if (response.ok) {
          // Check if token has expired.
          if (body.status === 500) {
            throw new ExpiredTokenError();
          }

          if (body.status === 404) {
            throw new NotFoundError(body.message);
          }

          if (body.status && body.status !== 200 && body.status !== 202) {
            throw new Error(
              body.message || 'Something went wrong, please try again 2.'
            );
          }

          return body;
        }

        throw new Error(
          body.message || 'Something went wrong, please try again. 3'
        );
      });
  } catch (err) {
    console.log(err);
    return [];
  }
}

/**
 *
 * @param {String} endpoint
 * @param {String} api
 * This client function is different from the others because
 * it doesn't try to format response as JSON, the case for what this function was built
 * is because a file is returned from the endpoint
 */
export function clientFile(endpoint, { body = {}, api, ...customConfig }) {
  console.log('api on api client', api);
  // Clean endpoint from leading and trailing slashes /
  const cleanEndpoint = endpoint.replace(/^\/|\/$/g, '');
  const token = fetchToken();
  const sessionId = getFromSession('tempId');
  const baseUrl = getBaseUrl(api);
  const headers = { 'Content-Type': 'application/json' };

  if (token) {
    headers['rufus-access-token'] = token;
  }

  if (sessionId) {
    body.session_id = sessionId;
  }

  const config = {
    ...customConfig,
    body: JSON.stringify(body),
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  return window
    .fetch(`${baseUrl}/${cleanEndpoint}`, config)
    .then(async response => {
      let body = await response;

      if (response.ok) {
        // Check if token has expired.
        if (body.status === 500) {
          throw new ExpiredTokenError();
        }

        if (body.status === 404) {
          throw new NotFoundError(body.message);
        }

        if (body.status && body.status !== 200 && body.status !== 202) {
          throw new Error(
            body.message ||
              'Something went wrong, file client, please try again.'
          );
        }

        return body;
      }

      if (response.status === 500) {
        throw new ExpiredTokenError();
      }

      throw new Error(
        body.message || 'Something went wrong, please try again.'
      );
    });
}

export default client;
