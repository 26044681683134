import client, { ExpiredTokenError } from './api-client';
import {
  storeToken,
  fetchToken,
  getFromSession,
  getInfoFromSession,
  storeSession,
  destroySession,
  storeGroupsInfo,
  getGroupsInfo,
  getCurrentGroup,
  storeCollapsedStatus,
  storeCollapsedAssociates,
  storeIntroStep,
  storeIntroRunning,
  storeCookie,
  getCookieValues,
  setCurrentGroup,
  storeEstimatedCollapse,
  storeLoginTime,
} from './auth-utils';
import { Role } from './role-utils';

export async function getUser() {
  const token = fetchToken();
  const userId = getInfoFromSession('userId');
  const sessionId = getFromSession('tempId');

  if (!userId || !sessionId) {
    return Promise.resolve(null);
  }

  try {
    const { data } = await client('main/users/getUserMinInfoByUid', {
      method: 'POST',
      body: { t_user_id: userId },
    });

    return Promise.resolve({
      ...data,
      id: userId,
      uid: userId,
      role: {},
      token,
    });
  } catch (e) {
    if (e instanceof ExpiredTokenError) {
      cleanSession();

      return Promise.resolve(null);
    }

    return Promise.reject(e);
  }
}

export async function getCompany() {
  const userId = getInfoFromSession('userId');
  const companyId = getInfoFromSession('companyId');

  if (!userId || !companyId) {
    return Promise.resolve({});
  }

  try {
    const data = await client('main/groups/getCompanyMinInfoUId', {
      method: 'POST',
      body: { group_uid: companyId, t_user_id: userId },
    });

    return Promise.resolve({
      ...data,
      id: companyId,
      uid: companyId,
      type: 'company',
    });
  } catch (e) {
    if (e instanceof ExpiredTokenError) {
      cleanSession();

      return Promise.resolve({});
    }

    return Promise.reject(e);
  }
}

export async function getFacility(uid) {
  const userId = getInfoFromSession('userId');

  const { data } = await client('main/groups/getFacilityDetailsByUid', {
    method: 'POST',
    body: { uid: uid, t_user_id: userId },
  });

  return Promise.resolve({
    ...data,
    type: 'facility',
    uid,
  });
}

export async function getTeam(uid) {
  const userId = getInfoFromSession('userId');

  const { data } = await client('main/groups/getTeamMinInfoUId', {
    method: 'POST',
    body: { team_uid: uid, t_user_id: userId },
  });

  return Promise.resolve({
    ...data,
    type: 'team',
    uid,
  });
}

export async function getGroups() {
  const userGroups = getGroupsInfo();
  let currentGroup = getCurrentGroup();
  let joinedToken = getFromSession('tempId');

  // As first option, if the user has only one group
  // we will give him that as default
  // if (!currentGroup && userGroups.length === 1) {
  //   currentGroup = userGroups[0];
  // }

  if (currentGroup) {
    // Once we have the current group, we need to figure how to get the role the user has in there.
    let role = Role.find(currentGroup.r);

    return {
      current: { ...currentGroup, role, token: joinedToken },
      availables: userGroups,
    };
  }

  return { availables: userGroups, current: null };
}

export async function joinGroup(group = {}) {
  if (!group.hasOwnProperty('g')) {
    throw new Error('The uid of the group is required to join.');
  }

  if (!group.hasOwnProperty('r')) {
    throw new Error('The role you have in the group is required to join.');
  }

  const userId = getInfoFromSession('userId');
  const groupId = group.g;
  const role = group.r;

  return client('main/groups/joinedGroup', {
    method: 'POST',
    body: {
      group_uid: groupId,
      user_uid: userId,
      role_uid: role,
      t_user_id: userId,
      web: true,
    },
  }).then(({ data }) => {
    setCurrentGroup(group);
    storeSession('tempId', data.id);
    return data;
  });
}

export async function leftCurrentGroup() {
  const joinedId = getFromSession('tempId');
  const userId = getInfoFromSession('userId');
  const group = await getCurrentGroup();
  const groupId = await group.g;

  return client('main/groups/leftGroup', {
    method: 'POST',
    body: {
      id: joinedId,
      t_user_id: userId,
      user_uid: userId,
      group_uid: groupId,
    },
  })
    .then(response => destroySession('tempId'))
    .catch(e => destroySession('tempId'));
}

export async function login(body) {
  return client('login', {
    method: 'POST',
    body,
  }).then(async ({ token, info, extra }) => {
    storeLoginTime();
    storeToken(token);
    storeGroupsInfo(extra);
    storeSession({ info });
    storeCollapsedStatus(false);
    storeCollapsedAssociates(false);
    storeIntroStep(0);
    storeIntroRunning(true);
    storeEstimatedCollapse(false);

    // In case user has only one role available, we will create the session automatically.
    if (extra.length === 1) {
      await joinGroup(extra[0]);
    }

    let actualCookies = getCookieValues();
    let releaseCookieExists = actualCookies.find(el => el.key === 'rn_wh');
    if (releaseCookieExists === undefined) {
      storeCookie('rn_wh', 13);
    }
  });
}

export async function refreshSession({ userId, group = {} }) {
  if (!group.hasOwnProperty('g')) {
    throw new Error('The uid of the group is required to join.');
  }

  if (!group.hasOwnProperty('r')) {
    throw new Error('The role you have in the group is required to join.');
  }

  const groupId = group.g;
  const role = group.r;

  return client('refreshSession', {
    method: 'POST',
    body: {
      group_uid: groupId,
      user_uid: userId,
      role_uid: role,
      t_user_id: userId,
    },
  }).then(async data => {
    const { token, sessionId } = data;
    storeLoginTime();
    storeToken(token);
    storeSession('tempId', sessionId);
    return { token, sessionId };
  });
}

/**
 * Method to logout user just on frontend side,
 * when backend returns an expired session error
 */
export async function cleanSession() {
  const token = fetchToken();

  if (token) {
    destroySession([
      'token',
      'info',
      'extra',
      'current',
      'collapsedStatus',
      'collapsedAssociates',
      'tempId',
      'estimatedCollapse',
    ]);
  }
}

export async function logout() {
  const userId = getInfoFromSession('userId');
  const companyId = getInfoFromSession('companyId');

  if (!userId) return;

  return client('forceLogout', {
    method: 'POST',
    body: {
      user_uid: userId,
      company_uid: companyId,
      notify_user: false,
    },
  })
    .then(() =>
      destroySession([
        'token',
        'info',
        'extra',
        'current',
        'collapsedStatus',
        'collapsedAssociates',
        'stepIntro',
        'introRunning',
        'estimatedCollapse',
      ])
    )
    .catch(e => {
      if (e instanceof TypeError) {
        throw e;
      }

      destroySession([
        'token',
        'info',
        'extra',
        'current',
        'collapsedStatus',
        'collapsedAssociates',
        'stepIntro',
        'introRunning',
        'estimatedCollapse',
      ]);
    });
}

export async function forgotPassword(values) {
  return client('forgotPassword', {
    method: 'POST',
    body: values,
  });
}
export async function resetPassword(values) {
  return client('resetPassword', {
    method: 'POST',
    body: values,
  });
}

export async function getInactivityRate() {
  const userId = getInfoFromSession('userId');

  if (!userId) {
    return Promise.resolve({});
  }

  try {
    const res = await client('main/users/getUserPref', {
      method: 'POST',
      body: { user_uid: userId, t_user_id: userId, pref_type: 700 },
    });

    return Promise.resolve(res.data);
  } catch (e) {
    if (e instanceof ExpiredTokenError) {
      cleanSession();

      return Promise.resolve({});
    }

    //If the endpoint returned a 400 status it means there is no preference for the user yet
    //we return a default value: 3600000 miliseconds = 1 hr.
    return Promise.resolve(3600000);
  }
}

export async function getStayLoggedIn() {
  const userId = getInfoFromSession('userId');

  if (!userId) {
    return Promise.resolve(false);
  }

  try {
    const res = await client('main/users/getUserPref', {
      method: 'POST',
      body: { user_uid: userId, t_user_id: userId, pref_type: 701 },
    });

    return Promise.resolve(res.data === '1');
  } catch (e) {
    if (e instanceof ExpiredTokenError) {
      cleanSession();

      return Promise.resolve({});
    }

    return Promise.resolve(false);
  }
}
