import React from "react";

export default function FullPageError({ error }) {
  return (
    <div
      role="alert"
      style={{
        color: "#ef5350",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <p>Oops, seems something went wrong. Try refreshing the app.</p>
      <pre>
        {error.message}{" "}
        {process.env.NODE_ENV !== "production" ? `- ${error.stack}` : null}
      </pre>
    </div>
  );
}
