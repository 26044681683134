import apiClient, {
  ExpiredTokenError,
  mediaClient,
  clientFile
} from "./api-client";
import { useAuth } from "../contexts/auth-context";
import { useCallback } from "react";
import { fetchToken } from "./auth-utils";

export function useClient() {
  const { forceLogout } = useAuth();

  const client = useCallback(
    (endpoint, options) => {
      const token = fetchToken();

      if (!token) {
        forceLogout();
        return Promise.reject("Your session was deleted or expired");
      }

      return apiClient(endpoint, options).catch(e => {
        if (e instanceof ExpiredTokenError) {
          forceLogout();
        }

        return Promise.reject(e);
      });
    },
    [forceLogout]
  );

  return client;
}

export function useMetricsClient() {
  const { forceLogout } = useAuth();

  const client = useCallback(
    (endpoint, options) => {
      const token = fetchToken();

      if (!token) {
        forceLogout();
        return Promise.reject("Your session was deleted or expired");
      }

      return apiClient(endpoint, { ...options, api: "metrics" }).catch(e => {
        if (e instanceof ExpiredTokenError) {
          forceLogout();
        }

        return Promise.reject(e);
      });
    },
    [forceLogout]
  );

  return client;
}

export function useCommunicationsClient() {
  const { forceLogout } = useAuth();

  const client = useCallback(
    (endpoint, options) => {
      const token = fetchToken();

      if (!token) {
        forceLogout();
        return Promise.reject("Your session was deleted or expired");
      }

      return apiClient(endpoint, { ...options, api: "messages" }).catch(e => {
        if (e instanceof ExpiredTokenError) {
          forceLogout();
        }

        return Promise.reject(e);
      });
    },
    [forceLogout]
  );

  return client;
}

export function useMediaClient() {
  const { forceLogout } = useAuth();

  const client = useCallback(
    (endpoint, options) => {
      const token = fetchToken();

      if (!token) {
        forceLogout();
        return Promise.reject("Your session was deleted or expired");
      }

      return mediaClient(endpoint, options).catch(e => {
        if (e instanceof ExpiredTokenError) {
          forceLogout();
        }

        return Promise.reject(e);
      });
    },
    [forceLogout]
  );

  return client;
}

export function useFileClient(server) {
  const { forceLogout } = useAuth();

  const client = useCallback(
    (endpoint, options) => {
      const token = fetchToken();

      if (!token) {
        forceLogout();
        return Promise.reject("Your session was deleted or expired");
      }

      return clientFile(endpoint, { ...options, api: server }).catch(e => {
        if (e instanceof ExpiredTokenError) {
          forceLogout();
        }

        return Promise.reject(e);
      });
    },
    [forceLogout]
  );

  return client;
}
