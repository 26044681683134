import { Role } from "../role-utils";
import Worker from "./Worker";

function TeamManager() {
  this.id = "44e90";
  this.name = "Team Manager";
  this.type = 3;
  // this.uid = '';
  this.below = [new Worker()];
}

TeamManager.id = "44e90";
// TeamManager.name = "Team Manager";

TeamManager.prototype = Role.prototype;

export default TeamManager;