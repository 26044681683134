import React from 'react';
import { Spin, Icon } from 'antd';

export default function FullPageLoader(props) {
    return (
        <Spin 
            tip="Loading..."
            indicator={
                <Icon type="loading" style={{ fontSize: '5rem' }}/>
            }
            className="full-page-loader"
        />
    )
}