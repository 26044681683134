const TOKEN_KEY = process.env.REACT_APP_JWT_TOKEN_KEY || 'token';

export function fetchToken() {
  return window.localStorage.getItem(TOKEN_KEY);
}

export function storeToken(token) {
  window.localStorage.setItem(TOKEN_KEY, token);
  return token;
}

export function destroyToken() {
  return window.localStorage.removeItem(TOKEN_KEY);
}

export function storeLoginTime() {
  return window.localStorage.setItem('loginTime', `${Date.now()}`);
}

// function to get the time diffrence from now to the last login time
export function getLoginTime() {
  let loginTime = window.localStorage.getItem('loginTime');
  if (!loginTime) return Date.now();
  return loginTime;
}

export function getFromSession(key) {
  return window.localStorage.getItem(key);
}

export function getInfoFromSession(key) {
  const info = getFromSession('info');

  if (!info) return null;
  //available keys in info string, with their respective lenghts
  const keys = {
    userId: [0, 36],
    companyId: [36, 72],
  };

  return keys.hasOwnProperty(key) ? info.substring(...keys[key]) : '';
}

export function storeGroupsInfo(groups) {
  window.localStorage.setItem('extra', JSON.stringify(groups));
  return groups;
}

export function getGroupsInfo() {
  let groupsStr = window.localStorage.getItem('extra');

  return groupsStr ? JSON.parse(groupsStr) : [];
}

export function setCurrentGroup(group) {
  window.localStorage.setItem('current', JSON.stringify(group));
  return group;
}

export function getCurrentGroup() {
  let group = window.localStorage.getItem('current');

  return group ? JSON.parse(group) : null;
}

export function storeSession(key, value) {
  if (typeof key === 'object') {
    return Object.keys(key).forEach(index => {
      window.localStorage.setItem(index, key[index]);
    });
  }

  return window.localStorage.setItem(key, value);
}

export function destroySession(key) {
  if (Array.isArray(key)) {
    return key.forEach(sessionKey =>
      window.localStorage.removeItem(sessionKey)
    );
  }

  return window.localStorage.removeItem(key);
}

export function storeCollapsedStatus(status) {
  window.localStorage.setItem('collapsedStatus', status);
  // return status;
}

export function getCollapsedStatus() {
  let status = window.localStorage.getItem('collapsedStatus');
  if (status === 'false') {
    return false;
  } else {
    return true;
  }
}
export function storeCollapsedAssociates(status) {
  window.localStorage.setItem('collapsedAssociates', status);
}

export function getCollapsedAssociates() {
  let status = window.localStorage.getItem('collapsedAssociates');
  if (status === 'false') {
    return false;
  } else {
    return true;
  }
}
export function storeIntroStep(index) {
  window.localStorage.setItem('stepIntro', index);
}

export function getStepIntro() {
  return window.localStorage.getItem('stepIntro');
}
export function storeIntroRunning(status) {
  window.localStorage.setItem('introRunning', status);
}
export function getIntroRunning() {
  let status = window.localStorage.getItem('introRunning');
  if (status === 'false') {
    return false;
  } else {
    return true;
  }
}
export function removeRememberComp() {
  document.cookie = `comp=;expires = Wed, 01 Jan 2020 00:00:00 GMT`;
}
export function removeRememberUser() {
  document.cookie = `uname=;expires = Wed, 01 Jan 2020 00:00:00 GMT`;
}
export function storeCookie(name, data) {
  document.cookie = `${name}=${data};expires = Sat, 01 Jan 2050 00:00:00 GMT`;
}

export function checkForCookie() {
  let cookies = [];
  let cookieArr = document.cookie.split(';');
  for (let i = 0; i < cookieArr.length; i++) {
    let cookiePair = cookieArr[i].split('=');

    if ('comp' === cookiePair[0].trim()) {
      cookies.push('company');
    }
    if ('uname' === cookiePair[0].trim()) {
      cookies.push('username');
    }
  }

  return cookies;
}

export function getCookieValues() {
  let cookies = [];
  let cookieArr = document.cookie.split(';');
  for (let i = 0; i < cookieArr.length; i++) {
    let cookiePair = cookieArr[i].split('=');

    /* Removing whitespace at the beginning of the cookie name
     and compare it with the given string */
    if ('comp' === cookiePair[0].trim()) {
      cookies.push({
        key: 'company',
        value: decodeURIComponent(cookiePair[1]),
      });
    }
    if ('uname' === cookiePair[0].trim()) {
      cookies.push({
        key: 'username',
        value: decodeURIComponent(cookiePair[1]),
      });
    }
    if ('rn_wh' === cookiePair[0].trim()) {
      cookies.push({
        key: cookiePair[0].trim(),
        value: decodeURIComponent(cookiePair[1]),
      });
    }

    //Remove wh_carousel_ad for WH-69 previously GAUNTLET-1156   || wh_auto_v2 for WH-17
    if (
      'wh_carousel_ad' === cookiePair[0].trim() ||
      'wh_auto_v2' === cookiePair[0].trim()
    ) {
      let boolean;
      if (decodeURIComponent(cookiePair[1]) === 'false') {
        boolean = false;
      } else {
        boolean = true;
      }

      cookies.push({
        key: cookiePair[0].trim(),
        value: boolean,
      });
    }
    //Remove after Devices Info v2 page becomes main  WH-17
    // if ('wh_device' === cookiePair[0].trim()) {
    //   cookies.push({
    //     key: cookiePair[0].trim(),
    //     value: decodeURIComponent(cookiePair[1]),
    //   });
    // }
  }
  //console.log(cookies);
  // Return null if not found
  return cookies;
}
export function storeEstimatedCollapse(status) {
  window.localStorage.setItem('estimatedCollapse', status);
}
export function getEstimatedCollapse() {
  let status = window.localStorage.getItem('estimatedCollapse');

  if (status === 'false') {
    return false;
  } else {
    return true;
  }
}

// WH-347, use this function to return true if any of users roles are Super Admin
export function hasSuperAdmin() {
  let extra = JSON.parse(window.localStorage.getItem('extra'));
  return extra.some(role => role.r === 'bf589a50-287d-4edd-be3b-b4428c1d4c39');
}
