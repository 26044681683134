import { Role } from "../role-utils";
import FacilityManager from "./FacilityManager";
import TeamManager from "./TeamManager";
import Worker from "./Worker";

function Admin() {
  this.id = "afec2";
  this.name = "Admin";
  this.type = 1;
  this.below = [new FacilityManager(), new TeamManager(), new Worker()];
}

Admin.id = "afec2";
// Admin.name = "Admin";

Admin.prototype = Role.prototype;

export default Admin;
