import React from "react";
import { Layout } from "antd";
import { BrowserRouter as Router } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import AuthProvider, { useAuth } from "./commons/contexts/auth-context";
//import CommunicationsProvider from "./commons/contexts/communication-context";
import WalkthroughProvider from "./commons/contexts/walkthrough-context";
import FullPageLoader from "./components/FullPageLoader";
import FullPageError from "./components/FullPageError";
//import Chat from "./components/Chat";

const AuthenticatedApp = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./authenticated-app")
);
const UnauthenticatedApp = React.lazy(() => import("./unauthenticated-app"));

function AppProviders({ children }) {
  return (
    <Layout className="App">
      <AuthProvider>
        {/* <CommunicationsProvider> */}
        <WalkthroughProvider>
          <Router>
            <DndProvider backend={Backend}>{children}</DndProvider>
          </Router>
          {/*  <Chat /> */}
        </WalkthroughProvider>
        {/* </CommunicationsProvider> */}
      </AuthProvider>
    </Layout>
  );
}

// function App() {
//   return (
//     <Layout className="App">
//       <AuthProvider>
//         {/* <CommunicationsProvider> */}
//         <WalkthroughProvider>
//           <Router>
//             <DndProvider backend={Backend}>
//               <AppRoutes />
//             </DndProvider>
//           </Router>
//           {/*  <Chat /> */}
//         </WalkthroughProvider>
//         {/* </CommunicationsProvider> */}
//       </AuthProvider>
//     </Layout>
//   );
// }

function App() {
  const { isLoggedIn } = useAuth();

  return (
  <ErrorBoundary FallbackComponent={FullPageError}>
      <React.Suspense fallback={<FullPageLoader />}>
             {isLoggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </React.Suspense>
    </ErrorBoundary>
  );
}

export default App;
export { AppProviders };
