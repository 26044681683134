import SuperAdmin from './roles/SuperAdmin';
import Admin from './roles/Admin';
import FacilityManager from './roles/FacilityManager';
import TeamManager from './roles/TeamManager';
import Worker from './roles/Worker';

export const SUPER_ADMIN = 'd4c39';
export const ADMIN = 'afec2';
export const FACILITY_MANAGER = 'da80a';
export const TEAM_MANAGER = '44e90';
export const WORKER = '168e0';

export const roles = [
  { id: SUPER_ADMIN, name: 'Super Admin', type: 0, class: SuperAdmin },
  { id: ADMIN, name: 'Admin', type: 1, class: Admin },
  {
    id: FACILITY_MANAGER,
    name: 'Facility Manager',
    type: 2,
    class: FacilityManager,
  },
  { id: TEAM_MANAGER, name: 'Team Manager', type: 3, class: TeamManager },
  { id: WORKER, name: 'Worker', type: 4, class: Worker },
];

export function Role() {
  this.id = '';
  this.name = '';
  this.uid = '';
}

Role.find = function(hint) {
  let role = roles.find(
    r =>
      r.id === hint ||
      (typeof hint === 'string' && r.id === hint.slice(-5)) ||
      r.name === hint ||
      r.type === hint
  );

  if (role) {
    return new role.class();
  }

  return new this();
};

Role.prototype.isSuperAdmin = function() {
  return this.id === SUPER_ADMIN;
};

Role.prototype.isAdmin = function() {
  // WH-347 Super Admin role also passes as true for isAdmin, so Super Admins can do anything an Admin can do; this prevents us from having to
  // add isSuperAdmin to EVERY instance of isAdmin throughout frontend.  Can use (isAdmin() && !isSuperAdmin()) when needing to target Admins directly
  return this.id === ADMIN || this.id === SUPER_ADMIN;
};

Role.prototype.isFacilityManager = function() {
  return this.id === FACILITY_MANAGER;
};

Role.prototype.isTeamManager = function() {
  return this.id === TEAM_MANAGER;
};

Role.prototype.isWorker = function() {
  return this.id === WORKER;
};

Role.prototype.is = function(role) {
  if (!(role instanceof Role)) {
    role = Role.find(role);
  }

  return role.id === this.id;
};

Role.prototype.isAbove = function(role) {
  if (!(role instanceof Role)) {
    role = Role.find(role);
  }

  if (this.isSuperAdmin() && role.isSuperAdmin()) return false;

  if (this.isSuperAdmin()) return true;

  if (
    this.isAdmin() &&
    (role.isFacilityManager() || role.isTeamManager() || role.isWorker())
  )
    return true;

  if (this.isFacilityManager() && (role.isTeamManager() || role.isWorker())) {
    return true;
  }

  if (this.isTeamManager() && role.isWorker()) {
    return true;
  }

  return false;
};

Role.prototype.isBelow = function(role) {
  if (!(role instanceof Role)) {
    role = Role.find(role);
  }

  if (this.isSuperAdmin() && role.isSuperAdmin()) return false;

  if (this.isAdmin() && role.isSuperAdmin()) return true;

  if (this.isFacilityManager() && (role.isAdmin() || role.isSuperAdmin()))
    return true;

  if (
    this.isTeamManager() &&
    (role.isSuperAdmin() || role.isAdmin() || role.isFacilityManager())
  )
    return true;

  if (this.isWorker() && !role.isWorker()) return true;

  return false;
};

Role.prototype.getBelow = function() {
  return this.below;
};
