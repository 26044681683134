// Auth context:
import React, { useContext, useState, useEffect } from 'react';
import { useAsync } from 'react-async';
import FullPageLoader from '../../components/FullPageLoader';
import FullPageError from '../../components/FullPageError';
import * as authClient from '../utils/auth-client';
import client, { ExpiredTokenError } from '../utils/api-client';
import { message } from 'antd';
import io from 'socket.io-client';

const MAIN_API_URL = process.env.REACT_APP_MAIN_API;

export const AuthContext = React.createContext(null);
AuthContext.displayName = 'AuthContext';

const initialState = {
  isLoggedIn: false,
  user: {},
  company: {},
  groups: { availables: [], current: {} },
  group: {},
  inactivityRate: '',
  stayLoggedIn: false,
};

async function bootstrap() {
  // First, we get the info stored in the local storage
  const sessionInfo = window.localStorage.getItem('info');

  // Which means there is no session.
  if (!sessionInfo) return initialState;

  const groups = await authClient.getGroups();

  // If he has more than one option to select, we will give him the chance to select.
  if (!groups.current) {
    return {
      ...initialState,
      groups,
    };
  }

  const user = await authClient.getUser();

  if (!user) return initialState;

  const company = await authClient.getCompany();
  let group;

  user.role = groups.current.role;
  if (user.role.isAdmin()) group = company;

  if (user.role.isFacilityManager())
    group = await authClient.getFacility(groups.current.g);

  if (user.role.isTeamManager() || user.role.isWorker())
    group = await authClient.getTeam(groups.current.g);

  const inactivityRate = await authClient.getInactivityRate();

  let stayLoggedIn = false;
  if (user.role.isAdmin()) stayLoggedIn = await authClient.getStayLoggedIn();

  return {
    ...initialState,
    isLoggedIn: true,
    user,
    company,
    groups,
    group,
    inactivityRate,
    stayLoggedIn,
  };
}

export default function AuthProvider(props) {
  const [firstAttemptFinished, setFirstAttemptFinished] = useState(false);
  const {
    data = initialState,
    isPending,
    isRejected,
    isSettled,
    error,
    reload,
    setData,
  } = useAsync({
    promiseFn: bootstrap,
  });
  const [inactiveLoggedMsg, setInactiveLoggedMsg] = useState(false);
  const [favPages, setFavPages] = useState([]);
  const [isRefreshingToken, setIsRefreshingToken] = useState(false); // To avoid api calls when refreshing token

  // A ref to keep track of the trigger of this function
  // So it can't be called multiple times.
  const forcingLogout = React.useRef(false);
  const socket = React.useRef();

  useEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true);
    }
  }, [isSettled]);

  useEffect(() => {
    if (!data?.isLoggedIn) return;

    client('main/users/getUserPref', {
      method: 'POST',
      body: {
        user_uid: data.user.uid,
        t_user_id: data.user.uid,
        pref_type: 900,
      },
    })
      .then(res => {
        if (res.data.length < 1) {
          setFavPages([]);
        } else {
          const favs = res.data.split(',').map(val => parseInt(val));
          setFavPages(favs);
        }
      })
      .catch(e => {
        setFavPages([]);
      });
  }, [data?.user?.uid]);

  // Socket effect to know in real time when a user is logged out by force
  React.useEffect(() => {
    if (data && data.isLoggedIn) {
      //Connect to main socket
      const innerSocket = io.connect(`${MAIN_API_URL}/mainSocket`, {
        path: '/socket.main',
        query: { params: JSON.stringify({ user_id: data.user.uid }) },
        transports: ['websocket'],
        // forceNew: true
      });
      innerSocket.on('connect', () => {
        innerSocket.on('ForceLogout', e => {
          message.error('You have been force logged out of your session.');
          authClient.cleanSession();
          setData(initialState);
        });
      });

      socket.current = innerSocket;
    }

    return () => {
      if (socket.current) {
        socket.current.removeAllListeners();
        socket.current.disconnect();
        socket.current = undefined;
      }
    };
  }, [data, setData]);

  const login = React.useCallback(
    async form => {
      await authClient.login(form);
      setInactiveLoggedMsg(false);
      await reload();
    },
    [reload]
  );

  const logout = React.useCallback(() => {
    authClient.logout();
    setData(initialState);
  }, [setData]);

  const forceLogout = React.useCallback(async () => {
    if (!forcingLogout.current) {
      forcingLogout.current = true;
      message.error('Your session was deleted or expired');
      await authClient.cleanSession();
      setData(initialState);
      // To avoid change ref immediately
      setTimeout(() => (forcingLogout.current = false), 1000);
    }
  }, [setData]);

  const updateCompany = React.useCallback(
    async form => {
      return client('main/groups/updateCompanyContactInfo', {
        method: 'POST',
        body: form,
      })
        .then(res => {
          reload();
          return Promise.resolve(res);
        })
        .catch(e => {
          if (e instanceof ExpiredTokenError) {
            authClient.cleanSession();
          }

          return Promise.reject(e);
        });
    },
    [reload]
  );

  const updateUser = React.useCallback(
    form => {
      return client('main/users/updateUser', {
        method: 'POST',
        body: form,
      })
        .then(res => {
          reload();
          return Promise.resolve(res);
        })
        .catch(e => {
          if (e instanceof ExpiredTokenError) {
            authClient.cleanSession();
          }

          return Promise.reject(e);
        });
    },
    [reload]
  );

  const selectGroup = React.useCallback(
    async group => {
      if (!group.enabled) {
        throw new Error("You can't login into a disabled group.");
      }

      if (data.groups.current) {
        await authClient.leftCurrentGroup();
      }

      await authClient.joinGroup(group);

      reload();
    },
    [data, reload]
  );

  const forgotPassword = React.useCallback(
    email => authClient.forgotPassword(email),
    []
  );

  const resetPassword = React.useCallback(
    values => authClient.resetPassword(values),
    []
  );

  const updateInactivityRate = React.useCallback(userId => {
    return client('main/users/getUserPref', {
      method: 'POST',
      body: { user_uid: userId, t_user_id: userId, pref_type: 700 },
    })
      .then(res => {
        document.location.reload();
        return Promise.resolve(res.data);
      })
      .catch(e => {
        if (e instanceof ExpiredTokenError) {
          authClient.cleanSession();
        }

        return Promise.reject(e);
      });
  }, []);

  const showInactivityMsg = React.useCallback(
    value => setInactiveLoggedMsg(value),
    []
  );

  const updateFavoritePages = React.useCallback(
    newFavPages => {
      if (!data?.isLoggedIn) return;

      let url;
      const isEmpty = newFavPages.length < 1;
      if (isEmpty) {
        url = 'main/users/deleteUserPref';
      } else {
        url = 'main/users/setUserPref';
      }
      return client(url, {
        method: 'POST',
        body: {
          user_uid: data.user.uid,
          pref_type: 900,
          pref_value: newFavPages,
          t_user_id: data.user.uid,
        },
      })
        .then(res => {
          if (isEmpty) {
            setFavPages([]);
          } else {
            const favs = newFavPages.split(',').map(val => parseInt(val));
            setFavPages(favs);
          }
          return Promise.resolve(res);
        })
        .catch(e => {
          console.log(e);
          return Promise.reject(e);
        });
    },
    [data.user]
  );

  if (!firstAttemptFinished) {
    if (isPending) {
      return <FullPageLoader />;
    }
  }

  if (isRejected) {
    return <FullPageError error={error} />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...data,
        favPages,
        inactiveLoggedMsg,
        isRefreshingToken,
        setIsRefreshingToken,
        login,
        logout,
        updateCompany,
        updateUser,
        selectGroup,
        forceLogout,
        forgotPassword,
        resetPassword,
        updateInactivityRate,
        showInactivityMsg,
        updateFavoritePages,
      }}
      {...props}
    />
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}
